import React from 'react'

import {
  Divider,
  Dropzone,
  Link,
  List,
  ListItem,
  Paragraph
} from '@te-digi/styleguide'

import { Content } from '../../components/Content'
import { FormLayoutWrapper } from '../../components/FormLayoutWrapper'
import { Playground } from '../../components/Playground'
import { Section } from '../../components/Section'
import { UploadFileListExample1 } from '../../examples/react/UploadFileListExample1'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="Dropzone"
    components={[{ component: Dropzone }]}
    status={[{ type: 'accessible', version: '15.1.0' }]}
  >
    <Section>
      <Playground
        enableOverflow
        WrapperComponent={FormLayoutWrapper}
      >
        <Dropzone
          accept={['.gif', '.jpeg', '.jpg', '.pdf', '.png', '.tif', '.tiff']}
          maxSize={5000000}
          minSize={1}
          buttonLabel="Valitse liitetiedosto"
          label="Raahaa liite tähän"
          overlayText="Pudota liite tähän"
          onDrop={() => console.log('Dropzone: onDrop')}
          onDropRejected={() => {
            // This is intentional
          }}
        />
      </Playground>
      <Playground
        enableOverflow
        WrapperComponent={FormLayoutWrapper}
      >
        <Dropzone
          accept={['.gif', '.jpeg', '.jpg', '.pdf', '.png', '.tif', '.tiff']}
          maxSize={5000000}
          minSize={1}
          buttonLabel="Valitse liitetiedosto"
          label="Raahaa liite tähän"
          overlayText="Pudota liite tähän"
          onDrop={() => console.log('Dropzone: onDrop')}
          onDropRejected={() => {
            // This is intentional
          }}
        >
          <Divider />
          {UploadFileListExample1()}
        </Dropzone>
      </Playground>
    </Section>
    <Section title="Saavutettavuus">
      <Paragraph>
        Komponentti ei automaattisesti ilmoita latauksen edistymisestä
        ruudunlukijalle. On suositeltavaa lisätä ilmoitus vähintään silloin, kun
        lataus on valmis, käyttämällä piilotettua ilmoitusta (
        <Link href="/palautteet/notifications/">Hidden notification</Link>).
      </Paragraph>
      <Paragraph noMargin>Tarkistuslista:</Paragraph>
      <List variant="unordered">
        <ListItem>
          Varmista, että dropzonen yhteydessä on label, joka kertoo
          mahdollisista vaatimuksista tiedoston suhteen, kuten tiedoston tyypin
          tai koon.
        </ListItem>
        <ListItem>
          Varmista, että tiedoston lataamisen jälkeen tiedosto on poistettavissa
          ja avattavissa.
        </ListItem>
        <ListItem>
          Mikäli tiedostoa ei pystytä lataamaan, virheviestissä pitää kertoa,
          miksi tiedoston lataaminen ei onnistunut. Tässä pitää käyttää
          notifikaatiota.
        </ListItem>
      </List>
    </Section>
  </Content>
)

export default Page
